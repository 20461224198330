import * as React from "react";
import { Collapse } from "react-collapse";
import Container from "./styled";

class Desplegable extends React.Component {
  render() {
    return (
      <Container>
        <div className="header">
          <div className="texto">
            <p>{this.props.children}</p>
          </div>
        </div>
      </Container>
    );
  }
}

export default Desplegable;